const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const config = {
    version: "v1.6.0",
    storageKeys: {
        auth: "sicp-auth"
    },
    baseNames: {
        web: isDevelopment ? "/" : "/",
        // api: isDevelopment ? "http://localhost:28300" : "https://oj.problemoverflow.cn:28300"
       api: isDevelopment ? "http://localhost:28300" : "https://back.compilers.cpl.icu"
    }
};

export default config;
